import React, { useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'

import './checkpointModal.scss'
export default function checkpointModal(props) {

    const closeModal = () => {
        props.setCheckpointShow(false)
        props.resume()
    }

    return (
        <>
            <Modal className="checkpoint-modal" size="md" show={props.checkpointShow} aria-labelledby="signup-modal" backdrop="static" keyboard="false">
                <Modal.Header>
                    <Modal.Title>
                        Welcome back
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="text-center" sm={{ span: 10, offset: 1 }}>
                            <p>Looks like you may not have completed this section. Press continue to resume where you left off.</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center">
                            <Button
                                variant="primary"
                                className="large-action-btn"
                                onClick={() => closeModal()}>
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
